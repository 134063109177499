import "../sass/styles.scss";

const sticky = 45;

function initMap() {
  var mapOptions = {
    "center":{
       "lat":-1,
       "lng":0
    },
    "clickableIcons":true,
    "disableDoubleClickZoom":false,
    "draggable":true,
    "fullscreenControl":false,
    "keyboardShortcuts":false,
    "mapTypeControl":false,
    "mapTypeControlOptions":{
       "text":"Default (depends on viewport size etc.)",
       "style":0
    },
    "mapTypeId":"roadmap",
    "rotateControl":true,
    "scaleControl":false,
    "scrollwheel":true,
    "streetViewControl":false,
    "styles":[
       {
          "featureType":"all",
          "elementType":"labels",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"administrative",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"administrative.country",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"administrative.province",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"administrative.locality",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"administrative.neighborhood",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"administrative.land_parcel",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"landscape",
          "elementType":"all",
          "stylers":[
             {
                "visibility":"on"
             }
          ]
       },
       {
          "featureType":"landscape",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             },
             {
                "hue":"#ff0000"
             }
          ]
       },
       {
          "featureType":"landscape",
          "elementType":"labels",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"landscape.man_made",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"on"
             },
             {
                "color":"#944242"
             }
          ]
       },
       {
          "featureType":"landscape.man_made",
          "elementType":"geometry.fill",
          "stylers":[
             {
                "color":"#bfbfbf"
             }
          ]
       },
       {
          "featureType":"landscape.natural",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"on"
             },
             {
                "color":"#bfbfbf"
             }
          ]
       },
       {
          "featureType":"landscape.natural.landcover",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"landscape.natural.terrain",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             },
             {
                "saturation":"-1"
             }
          ]
       },
       {
          "featureType":"poi",
          "elementType":"all",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"poi",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"poi.attraction",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"road",
          "elementType":"geometry.stroke",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"road.highway",
          "elementType":"geometry.fill",
          "stylers":[
             {
                "color":"#292929"
             }
          ]
       },
       {
          "featureType":"road.highway",
          "elementType":"geometry.stroke",
          "stylers":[
             {
                "visibility":"off"
             },
             {
                "color":"#494949"
             },
             {
                "saturation":"-85"
             }
          ]
       },
       {
          "featureType":"road.arterial",
          "elementType":"geometry.fill",
          "stylers":[
             {
                "color":"#888888"
             },
             {
                "visibility":"on"
             }
          ]
       },
       {
          "featureType":"road.local",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"road.local",
          "elementType":"geometry.fill",
          "stylers":[
             {
                "color":"#7f7f7f"
             }
          ]
       },
       {
          "featureType":"transit",
          "elementType":"all",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"transit",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"transit.line",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"transit.station",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"transit.station.airport",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"transit.station.bus",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"transit.station.rail",
          "elementType":"geometry",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       },
       {
          "featureType":"water",
          "elementType":"geometry",
          "stylers":[
             {
                "color":"#ffffff"
             }
          ]
       },
       {
          "featureType":"water",
          "elementType":"geometry.fill",
          "stylers":[
             {
                "color":"#ffffff"
             }
          ]
       },
       {
          "featureType":"water",
          "elementType":"geometry.stroke",
          "stylers":[
             {
                "visibility":"off"
             }
          ]
       }
    ],
    "zoom":2,
    "zoomControl":true
 };

  var mapElement = document.getElementById('locations-map');
  var map = new google.maps.Map(mapElement, mapOptions);
  var marker0 = new google.maps.Marker({
    title: "San Francisco, California", 
    icon: "https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508", 
    position: new google.maps.LatLng(37.7749295, -122.4194155), map: map});

  var infowindow0 = new google.maps.InfoWindow({
    content: "<h3 class=\"infoTitle\">San Francisco, California</h3><p class=\"info-description\">Office, flex, and remote work models available</p><a class=\"infoLink\" href=\"https://www.nextroll.com/careers/all?location=san-francisco\"><i class=\"fas fa-long-arrow-right\"></i>Open Positions</a>",
    map: map
  });

  marker0.addListener('click', function () {
    infowindow0.open(map, marker0);
    marker0.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon-selected.png?alt=media&token=a1f2efba-1ed3-463d-ba92-44b4c78997d3"); 
  }); 

  google.maps.event.addListener(infowindow0,'closeclick',function(){
    marker0.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508");
  });

  infowindow0.close();

  var marker1 = new google.maps.Marker({
    title: "New York City, New York", 
    icon: "https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508", 
    position: new google.maps.LatLng(40.7127753, -74.0059728), 
    map: map
  });

  var infowindow1 = new google.maps.InfoWindow({content: "<h3 class=\"infoTitle\">New York City, New York</h3><p class=\"info-description\">Office, flex, and remote work models available</p><a class=\"infoLink\" href=\"https://www.nextroll.com/careers/all?location=new-york\"><i class=\"fas fa-long-arrow-right\"></i>Open Positions</a>", map: map });

  marker1.addListener('click', function () { 
    infowindow1.open(map, marker1);
    marker1.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon-selected.png?alt=media&token=a1f2efba-1ed3-463d-ba92-44b4c78997d3"); 
  }); 

  google.maps.event.addListener(infowindow1,'closeclick',function(){
    marker1.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508");
  });
  infowindow1.close();

  var marker3 = new google.maps.Marker({
    title: "Dublin, Ireland",
    icon: "https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508",
    position: new google.maps.LatLng(53.3498053, -6.2603097),
    map: map
  });

  var infowindow3 = new google.maps.InfoWindow({ content: "<h3 class=\"infoTitle\">Dublin, Ireland</h3><p class=\"info-description\">Office, flex, and remote work models available</p><a class=\"infoLink\" href=\"https://www.nextroll.com/careers/all?location=dublin\"><i class=\"fas fa-long-arrow-right\"></i>Open Positions</a>", map: map });

  marker3.addListener('click', function () {
    infowindow3.open(map, marker3); 
    marker3.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon-selected.png?alt=media&token=a1f2efba-1ed3-463d-ba92-44b4c78997d3"); 
  }); 

  google.maps.event.addListener(infowindow3,'closeclick',function(){
    marker3.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508");
  });

  infowindow3.close();
// new marker
  var marker4 = new google.maps.Marker({
    title: "Sydney, Australia",
    icon: "https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508",
    position: new google.maps.LatLng(-33.8688197, 151.2092955),
    map: map
  });

  var infowindow4 = new google.maps.InfoWindow({content: "<h3 class=\"infoTitle\">Sydney, Australia</h3><p class=\"info-description\">Office, flex, and remote work models available</p><a class=\"infoLink\" href=\"https://www.nextroll.com/careers/all?location=sydney\"><i class=\"fas fa-long-arrow-right\"></i>Open Positions</a>", map: map });

  marker4.addListener('click', function () { 
    infowindow4.open(map, marker4); 
    marker4.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon-selected.png?alt=media&token=a1f2efba-1ed3-463d-ba92-44b4c78997d3"); 
  }); 

  google.maps.event.addListener(infowindow4,'closeclick',function(){
    marker4.setIcon("https://firebasestorage.googleapis.com/v0/b/ambition-a66e3.appspot.com/o/map-icon.png?alt=media&token=fa1c4baa-f17e-41ea-bee0-27533085a508"); //removes the marker
    // then, remove the infowindows name from the array
  });
  infowindow4.close();

  var heatmap = new google.maps.visualization.HeatmapLayer({ data: [] }); 
  heatmap.setOptions({
    "dissipating": false,
    "opacity": 0.6,
    "radius": 2
  }); 
  heatmap.setMap(map);

  google.maps.event.addDomListener(window, "resize", function () { var center = map.getCenter(); google.maps.event.trigger(map, "resize"); map.setCenter(center); });
}
google.maps.event.addDomListener(window, 'load', initMap);

function init() {
  const mobileMenuButton = document.querySelector('.menu-toggle');
  const sectionSwitcher = document.querySelectorAll('.menu-link');
  const urlParams = new URLSearchParams(window.location.search);
  const ab_test = urlParams.get('exp');
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  mobileMenuButton.addEventListener("click", (event) => {
    event.preventDefault();
    document.body.classList.toggle("open-nav");
  });
  sectionSwitcher.forEach( (link) => {
    link.addEventListener("click", (event) => {
      const goTo = event.target.hash.split("/");
      scrollTo(goTo[1]);
    });
  });
  if(window.location.hash){
    const path = window.location.hash.split('/');
    scrollTo(path[1]);
  }
  if(ab_test){
    const cta = document.querySelector("#target-cta");
    cta.innerHTML = '<a class=\"infoLink\" href="https://boards.greenhouse.io/nextrollsales/jobs/5143516002?gh_src=2a6127392us" class="button button--outline-alt" target="_blank">Find Your Path to Success <i class="fas fa-long-arrow-right"></i></a>';
  }
}
function scrollTo(id) {
  const yOffset = -100; 
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: y, behavior: 'smooth'});
}
document.addEventListener("DOMContentLoaded", init);
window.addEventListener("scroll", () => {
    if (window.pageYOffset > sticky) {
        document.body.classList.add("sticky");
      } else {
        document.body.classList.remove("sticky");
      }
});
